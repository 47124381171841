import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { JitsiMeeting } from "@jitsi/react-sdk";
import JitsiComponent from "./JitsiComponent";

const VideoCallModal = ({ isOpen, onClose, room, setStartCall, setOpenVideo, startCall }) => {

  const handleModalClose = () => {
    setStartCall(false);
    setOpenVideo(false)
    onClose();
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleModalClose} center>
        {(isOpen && startCall) && (
          <JitsiComponent handleModalClose={handleModalClose} roomName={room} />
        )}
      </Modal>
    </div>
  );
};

export default VideoCallModal;
