import React, { FC, useEffect, useRef } from "react";

const JitsiComponent = ({ handleModalClose, roomName }) => {
  // Function to generate a random room name

  const jitsiContainerRef = useRef(null);
  const apiRef = useRef(null);

  useEffect(() => {
    const loadJitsiScript = async () => {
      if (window.JitsiMeetExternalAPI) {
        return Promise.resolve();
      }
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src =
          "https://8x8.vc/vpaas-magic-cookie-8cc8097ef6414b649b36250dd3c2d122/external_api.js";
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const setupJitsi = async () => {
      try {
        await loadJitsiScript();
        
        // Check if JitsiMeetExternalAPI is available before proceeding
        if (window.JitsiMeetExternalAPI) {
          apiRef.current = new window.JitsiMeetExternalAPI("8x8.vc", {
            roomName: "test",
            parentNode: jitsiContainerRef.current,
            // jwt: "YOUR_JWT_TOKEN", // Use your JWT here
          });

          apiRef.current.addEventListener("readyToClose", () => {
            handleModalClose();
          });
        }
      } catch (error) {
        console.error("Failed to load Jitsi Meet API", error);
      }
    };

    setupJitsi();

    // Cleanup the API when the component unmounts
    return () => {
      if (apiRef.current) {
        apiRef.current.dispose();
      }
    };
  }, [handleModalClose]);

  return (
    <div
      id="jaas-container"
      ref={jitsiContainerRef}
      style={{ height: "600px", width: "450px" }}
    />
  );
};

export default JitsiComponent;
